import React, { useEffect } from 'react';
import { Widget, addResponseMessage } from 'react-chat-widget';
import './chat-widget.css';
import 'react-chat-widget/lib/styles.css';


function App() {
  useEffect(() => {
    addResponseMessage('I am your friendly geonerd. Ask me anything!');
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    try {
      const response = await fetch('https://askaboutminerals.com/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ question: newMessage }),
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      addResponseMessage(`${data.answer} \n\n (Source: ${data.sources})`);
      console.log('Chatbot response:', data)

    } catch (error) {
      console.error('Error sending message to chatbot:', error)
      addResponseMessage('Sorry, there was an error processing your request.');
    }
  };

    return (
      <div className="App">
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          title="Mineral Chat Bot"
          subtitle="Together we rock!"
          resizable={true}
        />
      </div>
    );
}

export default App;